import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Helmet from 'react-helmet';

const SEO = ({
  meta_description,
  lang,
  meta,
  keywords,
  meta_title,
  og_title,
  og_description,
  og_type,
  og_image,
  isLp
}) => {
  const { site } = useStaticQuery(
    graphql`
      {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  );

  const { siteMetadata } = site;

  const metaTitle = meta_title || siteMetadata.title;
  const metaDescription =
    meta_description || siteMetadata.description;
  const ogTitle = og_title || siteMetadata.title;
  const ogDescription = og_description || siteMetadata.description;
  const ogType = og_type || 'website';
  const ogImage = og_image || '';
  const metas = [
    {
      name: `title`,
      content: metaTitle
    },
    {
      name: `description`,
      content: metaDescription
    },
    {
      property: `og:title`,
      content: ogTitle
    },
    {
      property: `og:description`,
      content: ogDescription
    },
    {
      property: `og:type`,
      content: ogType
    },
    {
      property: `og:image`,
      content: ogImage
    },
    {
      name: `twitter:card`,
      content: `summary`
    },
    {
      name: `twitter:creator`,
      content: siteMetadata.author
    },
    {
      name: `twitter:title`,
      content: metaTitle
    },
    {
      name: `twitter:description`,
      content: metaDescription
    }
  ]
    .concat(
      keywords.length > 0
        ? {
            name: `keywords`,
            content: keywords.join(`, `)
          }
        : []
    )
    .concat(meta);

  return (
    <Helmet
      isLp={isLp}
      htmlAttributes={{ lang }}
      title={metaTitle}
      titleTemplate={isLp ? `%s` : `%s | ateliware`}
      meta={metas}
    ></Helmet>
  );
};

SEO.defaultProps = {
  lang: 'pt',
  meta: [],
  keywords: [],
  isLp: false
};

export default SEO;
