const colors = {
  white: '#ffffff',
  black: '#000000',
  gray50: '#f2f2f2',
  gray55: '#f3f3f3',
  gray100: '#bcbcbc',
  gray200: '#222222',
  gray300: '#333333',
  gray500: '#5f5f5f',
  gray600: '#5e5e5e',
  gray700: '#7a7a7a',
  gray900: '#242424',
  red200: '#ef5f86',
  red300: '#ef0044',
  red400: '#ef0343',
  red800: '#881a3e',
  yellow400: '#ffd35a',
  yellow500: '#ffae00',
  blue500: '#01b0f2',
  blue400: '#00C4EF',
  green500: '#26d100',
  gray98: '#fafafa'
};

export default colors;
