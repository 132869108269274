import styled from 'styled-components';
import colors from 'assets/global/colors';
import fonts from 'assets/global/fonts';

export const StyledWrapper = styled.div`
  max-width: 1280px;
  height: 100%;
  margin: 0 auto;
  padding: 0 24px;

  @media screen and (max-width: 768px) {
    padding: 0 23px;
  }

  @media screen and (max-width: 520px) {
    padding: 0 16px;
  }
`;

export const StyledTitle = styled.h1`
  margin-top: 0;
  margin-bottom: 40px;
  font-family: ${fonts.primary};
  font-size: 72px;
  color: ${colors.gray300};
  line-height: 1.1;
  width: 85%;

  @media screen and (max-width: 980px) {
    font-size: 60px;
    width: 100%;
  }

  @media screen and (max-width: 768px) {
    font-size: 42px;
  }

  @media screen and (max-width: 520px) {
    font-size: 28px;
  }
`;

export const StyledDashedTitle = styled.h1`
  position: relative;
  margin: 0 0 1rem 0;
  padding: 20px 0;
  font-family: ${fonts.primary};
  font-size: 36px;
  font-weight: 700;
  color: ${colors.gray300};

  @media screen and (min-width: 981px) {
    &:before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100px;
      height: 2px;
      background-color: ${colors.gray900};
      content: '';
    }
  }

  @media screen and (max-width: 980px) {
    font-size: 24px;
    font-weight: 600;
  }
`;

export const StyledSubtitle = styled.h2`
  position: relative;
  margin: ${(props) =>
    props.light || props.dark ? '0px' : '0 0 2rem 0'};
  padding: 20px 0px;
  font-family: ${fonts.primary};
  font-size: 28px;
  font-weight: 600;
  color: ${(props) => (props.dark ? colors.white : colors.gray300)};
  &:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100px;
    height: 2px;
    background-color: ${(props) =>
      props.dark ? colors.white : colors.gray900};
    content: '';
  }

  @media screen and (max-width: 980px) {
    font-size: 22px;
    font-weight: 600;
    padding: 0px;
    margin: 0px;
  }
`;
